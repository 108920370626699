import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OfflineManagerService } from './offline-manager.service';
import { NetworkService, ConnectionStatus } from './network.service';
import { Storage } from '@ionic/storage';
import { jsonpCallbackContext } from '@angular/common/http/src/module';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  private data = [];

  public stages = [];

  public systems = {};

  public terminology = null;

  private baseUrl = 'https://stemapp.theadpharm.com';
  // private baseUrl = 'http://localhost:8000';

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private storage: Storage) {

    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      // no internet - use previously fetched data from local storage
      this.getLocalData('wagtailData').then(d => {
        this.stages = d;
      });
    } else {
      // we have internet -- get from server, save copy to localstorage
      this.http.get(`${this.baseUrl}/stem/api/v1/documents.json`).subscribe((response) => {
        this.stages = response['documents'];
        this.setLocalData('wagtailData', this.stages);

        // this.checkTerms(); // testing
      });
    }
  }

  cleanString = (str: string) => {
    if ( ! str ) {
      return '';
    }

    return str.toUpperCase().trim()
      .replace('/', '')
      .replace('  ', ' ')
      .replace(',', '')
      .replace('.', '')
      .replace('(', '')
      .replace(')', '')
      .replace('\\U00A0', '');
  }

  // let termsStage = null;

  lookupTerm = (termString: string) => {
    const self = this;

    const termStringClean = self.cleanString( termString );

    console.log( 'looking up: ', termStringClean );

    if ( ! self.terminology ) {
      self.terminology = self.stages.filter(s => s.name === 'Terminology')[0];
    }

    const foundItems = self.terminology.content.filter(t => {
      const cleanTerm = self.cleanString( t.term );
      const cleanOther1 = self.cleanString( t.other_lookup_1 );
      const cleanOther2 = self.cleanString( t.other_lookup_2 );
      const cleanOther3 = self.cleanString( t.other_lookup_3 );
      const cleanOther4 = self.cleanString( t.other_lookup_4 );

      return (
        cleanTerm === termStringClean ||
        cleanOther1 === termStringClean ||
        cleanOther2 === termStringClean ||
        cleanOther3 === termStringClean ||
        cleanOther4 === termStringClean
      );
    });

    if ( foundItems && foundItems.length > 0 ) {
      return foundItems[0];
    }

    return null;
  }

  checkTerms() {
    const self = this;

    const theJson = JSON.stringify(this.stages);

    // console.log(theJson);

    const regex = /(<span class=\\"linked-term\\">)([a-zA-Z0-9\(\)\-\s]+)(<\/span)/g;

    const theTerms = [];

    var result;
    while((result = regex.exec(theJson)) !== null) {
      // console.log(result);
      theTerms.push(result[2]);
    }

    const uniqueTerms = Array.from( new Set(theTerms) ).sort();

    const successfulLookups = [];
    const failedLookups = [];

    for (const term of uniqueTerms) {
      const foundTerm = self.lookupTerm(term);
      if (foundTerm) {
        successfulLookups.push(term);
      } else {
        failedLookups.push(term);
      }
    }

    console.log('Successful: ', successfulLookups.length, ', Failed: ', failedLookups.length);

    console.log('success: ', successfulLookups);
    console.log('failed: ', failedLookups);
  }

  getStages(callback) {
    if (this.stages && this.stages.length > 0) {
      return callback(this.stages);
    }

    this.http.get(`${this.baseUrl}/stem/api/v1/documents.json`).subscribe((response) => {
      this.stages = response['documents'];
      return callback(this.stages);
    });
  }

  processSystemsSection(section, path) {
    const self = this;

    if (typeof section === 'string') {
      return;
    }

    if (section['section_meta_keys']) {
      section['section_meta_keys'].forEach(key => {
        if (!self.systems[key]) {
          self.systems[key] = {};
        }

        const pathName = path.join(' - ')

        if (! self.systems[key][pathName]) {
          self.systems[key][pathName] = [];
        }

        self.systems[key][pathName].push(section);
      });
    }

    // console.log(section);

    if (section.contents) {
      const newPath = JSON.parse(JSON.stringify(path));
      newPath.push(section.section_display_name);

      section.contents.forEach(subsection => {
        self.processSystemsSection(subsection, newPath);
      });
    }
  }

  getSystems(callback) {
    const self = this;

    if (this.systems && Object.keys(this.systems).length > 0) {
      return callback(this.systems);
    }

    this.getStages(stages => {
      if(stages) {
        stages.forEach(stage => {
          if (stage['content']) {
            stage['content'].forEach(section => {
              self.processSystemsSection(section, [ stage.name, ]);
            });
          }
        });
      }

      callback(self.systems);
    });
  }

  setData(id, data) {
      this.data[id] = data;
  }

  getData(id) {
      return this.data[id];
  }

  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${key}`, data);
  }

  // Get cached API result
  private async getLocalData(key) {
    const data =  await this.storage.get(`${key}`);
    return data;
    // console.log("the data: ", data);
    // return data ? JSON.parse(data) : null;
  }

}
