import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { $ } from 'protractor';

@Component({
  selector: 'app-complications',
  templateUrl: './complications.page.html',
  styleUrls: ['./complications.page.scss'],
})
export class ComplicationsPage implements OnInit {

  meta_tags: any;

  metaCategories = [
    { 'name': 'blood', display_name: 'Blood', 'content': [] },
    { 'name': 'cardiac', display_name: 'Cardiac', 'content': [] },
    { 'name': 'emotional', display_name: 'Emotional', 'content': [] },
    { 'name': 'eyes', display_name: 'Eyes', 'content': [] },
    { 'name': 'fatigue', display_name: 'Fatigue', 'content': [] },
    { 'name': 'gi', display_name: 'Gastrointestinal', 'content': [] },
    { 'name': 'gu', display_name: 'Genitourinary', 'content': [] },
    { 'name': 'hepatic', display_name: 'Hepatic', 'content': [] },
    { 'name': 'marrow', display_name: 'Marrow', 'content': [] },
    { 'name': 'muscles', display_name: 'Muscles', 'content': [] },
    { 'name': 'neuro', display_name: 'Neurological', 'content': [] },
    { 'name': 'oropharynx', display_name: 'Oropharynx', 'content': [] },
    // { 'name': 'pulmonary', display_name: 'Pulmonary', 'content': [] },
    { 'name': 'respiratory', display_name: 'Respiratory', 'content': [] },
    { 'name': 'skin', display_name: 'Skin', 'content': [] },
    { 'name': 'thermometer', display_name: 'Thermometer', 'content': [] },
  ];

  metaCategoryData = {};

  prevSystem() {
    var idx = this.metaCategories.indexOf(this.selectedSystem);
    if( idx > 0) {
      this.selectedSystem = this.metaCategories[idx-1];
    }
  }

  selectedSystem: any = this.metaCategories[0];

  nextSystem() {
    var idx = this.metaCategories.indexOf(this.selectedSystem);
    if( idx < this.metaCategories.length) {
      this.selectedSystem = this.metaCategories[idx+1];
    }
  }

  GoToSystem() {
    if (this.selectedSystem) {
      alert(this.selectedSystem['name']);
    }
  }

  constructor( private dataService: DataService ) {
    var self = this;

    self.meta_tags = [];

    self.dataService.getSystems(systems => {
      self.meta_tags = systems;
      console.log('--------->', self.meta_tags);
    });
   }

  ngOnInit() {
  }

}
