import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-disclaimer-modal',
  templateUrl: './disclaimer-modal.component.html',
  styleUrls: ['./disclaimer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DisclaimerModalComponent implements OnInit {

  stageData: any;
  disclaimerContent: any;
  steeringCommitteeContent: any;

  constructor(private dataService: DataService, private modalCtrl: ModalController) {
    var self = this;
    var stageName = 'Disclaimer & Steering Comittee';

    self.dataService.getStages(stages => {
      self.stageData = stages.filter(s => {
        return (s.name === stageName);
      })[0];

      // console.log(self.stageData);

      self.disclaimerContent = self.stageData.content.filter(el => {
        return (el.section_name === 'disclaimer');
      })[0];

      self.steeringCommitteeContent = self.stageData.content.filter(el => {
        return (el.section_name === 'steering-committee');
      })[0];

      // console.log('Disclaimer: ', self.disclaimerContent);
      // console.log('Steering Committee: ', self.steeringCommitteeContent);
    });
   }

   closeModal() {
    this.modalCtrl.dismiss();
   }

  ngOnInit() {}

}
