import { Injectable } from '@angular/core';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import PhotoSwipe from 'photoswipe'
 
@Injectable({
  providedIn: 'root'
})
export class PhotoViewerService {
 
  constructor() { }
 
  openImage(el: HTMLImageElement) {
    const pswpElement = document.querySelectorAll('.pswp')[0];

    const items = [
      {
        src: el.getAttribute("src"),
        w: el.naturalWidth,
        h: el.naturalHeight
      }
    ];

    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, {
      index: 0,
      shareEl: false,
      fullscreenEl: false
    });
    
    gallery.init();
  }
}

