import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { ModalController, } from '@ionic/angular';
import { TermModalComponent } from '../term-modal/term-modal.component';
import { PhotoViewerService } from '../services/photo-viewer.service';

@Component({
  selector: 'app-stage',
  templateUrl: './stage.page.html',
  styleUrls: ['./stage.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StagePage implements OnInit {

    stage: any;
    complications: any;
    checklist: any;

    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private dataService: DataService,
      public modalController: ModalController,
      private photoViewerService: PhotoViewerService,
    ) {
        var self = this;
        // console.log(this.dataService.stages.length);

        // this.dataService.stages.forEach((s) => {
        //   console.log('THE ID: ', this.route.snapshot.params.id, s.id);
        //   if (s.id.toString() === this.route.snapshot.params.id.toString()) {
        //     this.stage = s;
        //   }
        // });

        // console.log('Stage has been set: ', this.stage);

        const stageId = this.route.snapshot.params.id;

        this.dataService.getStages(function(stages) {
            stages.forEach((s) => {
              console.log('THE ID: ', stageId, s.id);
      
              if (s.id == stageId) {
                self.stage = s;
                console.log('Stage has been set: ', self.stage);

                self.complications = self.stage.content.filter(el => {
                    return el.section_name === 'complications';
                })[0];

                self.checklist = self.stage.content.filter(el => {
                    return el.section_name === 'checklist';
                })[0];
            
              }
            });
        });

    }

    ngOnInit() {
        // console.log(this.dataService.stages.length);
        // this.dataService.stages.forEach((s) => {
        //   if (s.id === this.route.snapshot.params.id) {
        //     this.stage = s;
        //   }
        // });

        // console.log('Stage has been set: ', this.stage);
    }

    next() {

    }

    goBack() {
        // this.location.back();
        this.router.navigate(['/']);
    }

    toggleLevel1Section(event) {
        var target = event.target || event.srcElement || event.currentTarget;
        var isOpen = target.closest('.section-wrapper-1').classList.contains('open');

        document.querySelectorAll('.section-wrapper-1,.body-panels').forEach(el => {
            el.classList.remove('open');
        });

        if (isOpen) {
            document.querySelectorAll('.section-wrapper-1,.body-panels').forEach(el => {
                el.classList.add('closed');
            });
        } else {
            document.querySelectorAll('.section-wrapper-1,.body-panels').forEach(el => {
                el.classList.remove('closed');
            });
            target.closest('.section-wrapper-1').classList.toggle('open');
            target.closest('.body-panels').classList.toggle('open');
        }

        document.querySelectorAll('.section-wrapper').forEach(el => {
            el.classList.remove('open');
        });
    }

    toggleLevel2Section(event) {
        var target = event.target || event.srcElement || event.currentTarget;

        document.querySelectorAll('.section-wrapper').forEach(el => {
            el.classList.remove('open');
        });

        target.closest('.section-wrapper').classList.toggle('open');
    }

    async displayTermModal( term ) {
        const modal = await this.modalController.create({
            component: TermModalComponent,
            componentProps: { term }
        });
        return await modal.present();
    }

    isStrArr(arr: unknown) {
      if (!Array.isArray(arr)) {
        return false;
      }

      return typeof arr[0] === "string";
    }

    onClick(e: HTMLElement) {
        console.log(e.id, e.className);
        if ( e.className && e.className.indexOf('linked-term') > -1 ) {
            const term = e.innerHTML.toString().toUpperCase().trim();
            this.displayTermModal( term );
        } 
        
        else if (e.tagName.toLowerCase() === "img") {
            // console.log("src", e.getAttribute("src"));
            this.photoViewerService.openImage(e as HTMLImageElement);
        }
    }

}


