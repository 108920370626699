import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, } from '@ionic/angular';
import { DataService } from '../services/data.service';
import { TermModalComponent } from '../term-modal/term-modal.component';
import { PhotoViewerService } from '../services/photo-viewer.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.page.html',
  styleUrls: ['./content.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentPage implements OnInit {

  stage: any;
  complications: any;
  section: any;
  subsection: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    public modalController: ModalController,
    private photoViewerService: PhotoViewerService,
  ) {
    var self = this;
    var stageId = this.route.snapshot.params.stage_id;
    var sectionName = this.route.snapshot.params.section;
    var subsectionName = this.route.snapshot.params.subsection;
    var subsubsectionName = this.route.snapshot.params.subsubsection;

    // console.log(stageId, sectionName, subsectionName, subsubsectionName);

    // console.log(this.dataService.stages.length);

    this.dataService.getStages(function(stages) {
      stages.forEach((s) => {
        console.log('THE ID: ', stageId, s.id);

        if (s.id == stageId) {
          self.stage = s;
          console.log('Stage has been set: ', self.stage);

          self.stage.content.forEach(c => {
            if (c.section_name === sectionName) {
              self.complications = c;

              self.complications.contents.forEach(c => {
                if (c.section_name === subsectionName) {
                  self.section = c;

                  self.section.contents.forEach(c => {
                    if (c.section_name === subsubsectionName) {
                      self.subsection = c;
                    }
                  });
                }
                
              });
            }
          });
  
        }

      });
    });
  }

  ngOnInit() {

  }

  async displayTermModal( term ) {
    const modal = await this.modalController.create({
      component: TermModalComponent,
      componentProps: { term }
    });
    return await modal.present();
  }        

  onClick(e:HTMLElement) {
    // console.log(e.id, e.className);
    if ( e.className && e.className.indexOf('linked-term') > -1 ) {
      var term = e.innerHTML.toString().toUpperCase().trim();
      this.displayTermModal( term );
    } else if (e.tagName.toLowerCase() === "img") {
      // console.log("src", e.getAttribute("src"));
      this.photoViewerService.openImage(e as HTMLImageElement);
    }
  }

  isStrArr(arr: unknown) {
    if (!Array.isArray(arr)) {
      return false;
    }

    return typeof arr[0] === 'string';
  }

  goBack() {
    // this.navCtrl.pop();
  }

}
