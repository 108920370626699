import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-terminology',
  templateUrl: './terminology.page.html',
  styleUrls: ['./terminology.page.scss'],
})
export class TerminologyPage implements OnInit {

  stageData: any;

  constructor(private dataService: DataService) {
    var self = this;
    var stageName = 'Terminology';

    self.dataService.getStages(stages => {
      self.stageData = stages.filter(s => {
        return (s.name === stageName);
      })[0];

      console.log(self.stageData);
    });
   }

  ngOnInit() {
  }

}
