import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { DataResolverService } from './resolver/data-resolver.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'stage', loadChildren: './stage/stage.module#StagePageModule' },
  {
    path: 'stage/:id', 
    // resolve: {
    //     stages: DataResolverService
    // },
    loadChildren: './stage/stage.module#StagePageModule' 
  },

  {
    path: 'stage/:stage_id/:section/:subsection/:subsubsection',
    // resolve: {
    //     stages: DataResolverService
    // },
    loadChildren: './content/content.module#ContentPageModule'
  },

  { path: 'content', loadChildren: './content/content.module#ContentPageModule' },
  {
      path: 'content/:id', 
      // resolve: {
      //     content: DataResolverService
      // },
      loadChildren: './content/content.module#ContentPageModule'
  },
  { path: 'terms-of-use', loadChildren: './terms-of-use/terms-of-use.module#TermsOfUsePageModule' },
  { path: 'terminology', loadChildren: './terminology/terminology.module#TerminologyPageModule' },
  { path: 'references', loadChildren: './references/references.module#ReferencesPageModule' },
  { path: 'complications', loadChildren: './complications/complications.module#ComplicationsPageModule' },
  { path: 'complications-system', loadChildren: './complications-system/complications-system.module#ComplicationsSystemPageModule' },
  {
    path: 'complications/:system',
    // resolve: {
    //     content: DataResolverService
    // },
    loadChildren: './complications-system/complications-system.module#ComplicationsSystemPageModule'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
