import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {  ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { DisclaimerModalComponent } from '../disclaimer-modal/disclaimer-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

    public page = {
        // "body": ""
    };

    public body = ""

    automaticClose = true;
    selectedStage = null;

    constructor(
        public sanitizer: DomSanitizer,
        private router: Router,
        private dataService: DataService,
        public modalController: ModalController,
    ) {

        this.presentDisclaimerModal();
    }

    mainNavItems() {
        return this.dataService.stages.filter(el => {
            return el.display_main_nav;
        });
    }

    next(id) {
        console.log(id);
        this.selectedStage = this.dataService.stages.filter((el) => {
            return (el.id === id);
        })[0];

        if (!this.selectedStage.enabled) {
            return alert('This section is not available in this beta');
        }

        this.router.navigateByUrl('/stage/' + this.selectedStage.id);
    }

    async presentInfoModal() {
        const modal = await this.modalController.create({
            component: InfoModalComponent,
        });
        return await modal.present();
    }

    async presentDisclaimerModal() {
        const modal = await this.modalController.create({
            component: DisclaimerModalComponent,
            componentProps: { value: 123 }
        });
        return await modal.present();
    }


    async choosing_wisely() {
        if (confirm('You will now be leaving the app, please confirm to continue')) {
            window.open('https://choosingwiselycanada.org/recommendations/');
        }
    }

    openYoutubeVideo() {
        console.log("YOUTUBE");
        /*
            TODO:
            change API KEY in 
            <preference name="YouTubeDataApiKey" value="[YOUR YOUTUBE API]" />
            which is located in config.xml (this is for android)
        */

        // const browser = this.iab.create('https://www.youtube.com/watch?v=8ZhoeSaPF-k');

        // browser.executeScript(...);

        // browser.insertCSS(...);
        // browser.on('loadstop').subscribe(event => {
        // browser.insertCSS({ code: "body{color: red;" });
        // });

        // browser.close();
    }

}
