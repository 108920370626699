import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { IonicStorageModule } from '@ionic/storage';
import { Network } from '@ionic-native/network/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { HomePageModule } from './home/home.module';
import { StagePageModule } from './stage/stage.module';
import { ContentPageModule } from './content/content.module';
import { TermsOfUsePageModule } from './terms-of-use/terms-of-use.module';
import { TerminologyPageModule } from './terminology/terminology.module';
import { ReferencesPageModule } from './references/references.module';
import { ComplicationsPageModule } from './complications/complications.module';
import { DisclaimerModalComponent } from './disclaimer-modal/disclaimer-modal.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { TermModalComponent } from './term-modal/term-modal.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ZoomAreaModule } from 'ionic2-zoom-area';

import { PhotoViewerModal } from './photo-viewier-modal/photo-viewer-modal.component';

@NgModule({
  declarations: [AppComponent, DisclaimerModalComponent, InfoModalComponent, TermModalComponent, PhotoViewerModal],
  entryComponents: [ DisclaimerModalComponent, PhotoViewerModal, InfoModalComponent, TermModalComponent,],
  imports: [
      BrowserModule, 
      // BrowserAnimationsModule.forRoot(),
      HttpClientModule,
      IonicModule.forRoot(), 
      // ZoomAreaModule.forRoot(),
      IonicStorageModule.forRoot(),
      AppRoutingModule,
      RouterModule.forRoot([
          { path: '', component: HomePageModule },
          { path: 'stage', component: StagePageModule },
          { path: 'content', component: ContentPageModule },
          { path: 'terminology', component: TerminologyPageModule },
          { path: 'references', component: ReferencesPageModule },
          { path: 'complications', component: ComplicationsPageModule },
      ]),
    ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Network,
    InAppBrowser
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
