import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-references',
  templateUrl: './references.page.html',
  styleUrls: ['./references.page.scss'],
})
export class ReferencesPage implements OnInit {
  stageData: any;

  constructor( private dataService: DataService ) {
    var self = this;
    var stageName = 'References';

    self.dataService.getStages(stages => {
      self.stageData = stages.filter(s => {
        return (s.name === stageName);
      })[0];

      console.log(self.stageData);
    });
   }

  ngOnInit() {
  }

}
