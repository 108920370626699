import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-term-modal',
  templateUrl: './term-modal.component.html',
  styleUrls: ['./term-modal.component.scss'],
})
export class TermModalComponent implements OnInit {
  term: string;
  foundDefinition: any;

  stageData: any;

  constructor( private dataService: DataService, private navParams: NavParams, private modalCtrl: ModalController  ) {
    var self = this;
    var stageName = 'Terminology';

    this.term = navParams.get( 'term' )

    self.dataService.getStages(stages => {
      self.stageData = stages.filter(s => {
        return (s.name === stageName);
      })[0];

      console.log(self.stageData);

      this.term = this.cleanString( this.term );

      const foundItems = this.stageData.content.filter(t => {
        const cleanTerm = self.cleanString( t.term );
        const cleanOther1 = self.cleanString( t.other_lookup_1 );
        const cleanOther2 = self.cleanString( t.other_lookup_2 );
        const cleanOther3 = self.cleanString( t.other_lookup_3 );
        const cleanOther4 = self.cleanString( t.other_lookup_4 );
        return (cleanTerm === this.term ||
          cleanOther1 === this.term ||
          cleanOther2 === this.term ||
          cleanOther3 === this.term ||
          cleanOther4 === this.term
        );
      });

      if ( foundItems && foundItems.length > 0 ) {
        self.foundDefinition = foundItems[0];
      }
    });
  }

  cleanString = (str: string) => {
    if ( ! str ) {
      return '';
    }

    return str
      .replace('/', '')
      .replace('  ', ' ')
      .replace(',', '')
      .replace('.', '')
      .replace('(', '')
      .replace(')', '')
      .replace('\\U00A0', '');
  }

  ngOnInit() {}

  closeModal() {
    this.modalCtrl.dismiss();
   }

}
