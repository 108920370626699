import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoModalComponent implements OnInit {
  stageData: any;
  infoContent: any;

  constructor(private dataService: DataService, private modalCtrl: ModalController) {
    var self = this;
    var stageName = 'Information Icon';

    self.dataService.getStages(stages => {
      self.stageData = stages.filter(s => {
        return (s.name === stageName);
      })[0];

      // console.log(self.stageData);

      self.infoContent = self.stageData.content.filter(el => {
        return (el.section_name === 'info');
      })[0];

      console.log('Info: ', self.infoContent);
    });
   }

   closeModal() {
    this.modalCtrl.dismiss();
   }

  ngOnInit() {}


}
